<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Concentrated sulfuric acid is sold as
        <stemble-latex content="$96.5\,\%\>$" />
        (w/w) solution which has a density of
        <stemble-latex content="$1.84\,\text{g/mL}$" />.
      </p>

      <p class="mb-2">
        (a) Calculate the molarity of concentrated
        <chemical-latex content="H2SO4" />
      </p>
      <calculation-input
        class="mb-5"
        v-model="inputs.input1"
        prepend-text="$\text{M}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (b) Starting with the concentrated acid, how would you prepare
        <stemble-latex content="$1000\,\text{mL}$" />
        solution of
        <stemble-latex content="$1.0\,\text{M}\>$" />
        <chemical-latex content="H2SO4?" />
      </p>
      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUPEI2210A4Q2',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        explainedResponse: null,
      },
    };
  },
};
</script>
